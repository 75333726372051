var searchKeys = [
// {
//     key: "mediachannel",
//     label: "关联合集名称",
//     placeholder: "请输入",
//     required: false,
//     rules: [],
//     input: true,
// }, 
{
  key: "date",
  label: "申请时间",
  format: "YYYY-MM-DD HH:mm",
  placeholder: ["开始时间", "截止时间"],
  rules: [],
  dates: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };