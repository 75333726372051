var columns = [{
  title: '申请ID',
  dataIndex: 'id',
  key: 'id',
  // width: '10%',
  scopedSlots: {
    customRender: 'id'
  }
}, {
  title: 'Username',
  dataIndex: 'username',
  key: 'username',
  // width: '14%',
  scopedSlots: {
    customRender: 'username'
  }
}, {
  title: '关联合集',
  dataIndex: 'mediachannel',
  key: 'mediachannel',
  // width: '10%',
  scopedSlots: {
    customRender: 'mediachannel'
  }
}, {
  title: '申请时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '14%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '审核状态',
  dataIndex: 'statusmsg',
  key: 'statusmsg',
  // width: '20%',
  scopedSlots: {
    customRender: 'statusmsg'
  }
}, {
  title: '操作',
  key: 'action',
  dataIndex: 'action',
  // fixed: "right",
  width: 200,
  scopedSlots: {
    customRender: 'action'
  }
}];
export { columns };